import React from "react";
import "./HeroSection.scss";
import { HeroSectionData } from "../../Assets/Data/constants";
import CirclePlay from "../../Assets/play.svg";
const HeroSection = () => {
	return (
		<div className="HeroSectionContainer">
			<div className="HeroDetails">
				<h1 className="Herotitle">
					{HeroSectionData && HeroSectionData.title}
				</h1>
				<p className="heroDesc">{HeroSectionData && HeroSectionData.desc}</p>
				<div className="HerobtnsContainer">
					<button className="getStarted">Get Started</button>
					{/* <button className="learnMoreBtn">
						<div className="playCircle">
							<img className="play" src={CirclePlay} alt="" />
						</div>
						Learn More
					</button> */}
				</div>
			</div>
		</div>
	);
};

export default HeroSection;
