import React from "react";
import "./Footer.scss";
import Logo from "../../Assets/HousinGramLogo.svg";
import LogoTitle from "../../Assets/HousinGram.svg";
import RightArrow from "../../Assets/right-arrow.svg";

const Footer = () => {
	return (
		<div className="FooterContainer">
			<div className="leftSection">
				<div className="logo">
					<img src={Logo} alt="" />
					<img src={LogoTitle} alt="" />
				</div>
				<div className="InputSearchField">
					<label htmlFor="">Get started now try our product</label>
					<div className="Inputcontainer">
						<input type="text" placeholder="Enter your email here" />
						<button className="circle">
							<img src={RightArrow} alt="" />
						</button>
					</div>
				</div>
				<p>© 2024 HousingGram. Copyright and rights reserved</p>
			</div>
			<div className="rightSection">
				<a href="Support">Support</a>
				<a href="Help centre">Help centre</a>
				<a href="Pricing">Pricing</a>
				<a href="About">About</a>
				<a href="Contact us">Contact us</a>
				<a href="Terms and Condtions">Terms and Condtions</a>
				<a href="Privacy Policy">Privacy Policy</a>
			</div>
		</div>
	);
};

export default Footer;
