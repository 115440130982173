import React, { useState, useEffect } from "react";
import "./Navbar.scss";
import Logo from "../../Assets/HousinGramLogo.svg";
import LogoTitle from "../../Assets/HousinGram.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { Modal } from "antd";

const Navbar = () => {
	const [open, setOpen] = useState(false);
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	// Update screen width on resize
	useEffect(() => {
		const handleResize = () => setScreenWidth(window.innerWidth);

		window.addEventListener("resize", handleResize);

		// Cleanup event listener on component unmount
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const AnchorStyles = {
		color: "#000000",
		textDecoration: "none",
		fontSize: "1.1rem",
		fontWeight: 700,
		lineHeight: "0.98rem",
	};
	const btnStyle = {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "1.1rem",
		fontWeight: 600,
		lineHeight: "0.98rem",
		padding: "0.8rem",
		borderRadius: "0.625rem",
		color: "#ffffff",
		border: "none",
		backgroundColor: "#2a565e",
	};

	return (
		<div className="NavbarContainer">
			<div className="logo">
				<img src={Logo} alt="Logo" />
				<img src={LogoTitle} alt="Logo Title" />
			</div>
			{screenWidth <= 768 && (
				<>
					{open ? (
						<button className="mobile-menu-icon" onClick={() => setOpen(false)}>
							<FaTimes />
						</button>
					) : (
						<button className="mobile-menu-icon" onClick={() => setOpen(true)}>
							<FaBars />
						</button>
					)}
				</>
			)}
			<div className={`navItems ${screenWidth <= 768 ? "hide" : ""}`}>
				<a href="">Service</a>
				<a href="">Pricing</a>
				<a href="">About</a>
				<button>Contact Us</button>
			</div>
			{screenWidth <= 768 && (
				<Modal
					open={open}
					style={{ top: 70, right: "-25%" }}
					mask={false}
					closable={false}
					onCancel={() => setOpen(!open)}
					footer={null}
					width={180}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "start",
							gap: "1.3rem",
							flexDirection: "column",
						}}
					>
						<a style={AnchorStyles} href="#" onClick={() => setOpen(false)}>
							Service
						</a>
						<a style={AnchorStyles} href="#" onClick={() => setOpen(false)}>
							Pricing
						</a>
						<a style={AnchorStyles} href="#" onClick={() => setOpen(false)}>
							About
						</a>
						<button style={btnStyle} onClick={() => setOpen(false)}>
							Contact Us
						</button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default Navbar;
