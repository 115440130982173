import React from "react";
import "./ContactPage.scss";
import { ContactPageData } from "../../Assets/Data/constants";

const ContactPage = () => {
	return (
		<div className="ContactPageContainer">
			<div className="item">
				<div className="details">
					<h1>{ContactPageData && ContactPageData.title}</h1>
					<img src={ContactPageData && ContactPageData.img} alt="" />
				</div>
				<form action="">
					<div className="InputFields">
						<label htmlFor="Name">Name</label>
						<input type="text" placeholder="Enter your name" />
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Email</label>
						<input type="email" placeholder="Enter your email" />
					</div>
					<div className="InputFields">
						<label htmlFor="Name">Message</label>
						<textarea rows={5} placeholder="What you say?"></textarea>
					</div>
					<button className="contactBtn" type="submit">
						Request Demo
					</button>
				</form>
			</div>
		</div>
	);
};

export default ContactPage;
