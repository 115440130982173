import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";

function router() {
	return (
		<div>
			<BrowserRouter>
				<Routes>
					<Route path="" element={<HomePage />} />
					<Route path="/karanb10" element={<Navigate to="/karanb10/index.html" replace />} />
					<Route path="/sales-development-manager-p4nslz7sy" element={<Navigate to="/sales-development-manager-p4nslz7sy/index.html" replace />} />
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default router;
