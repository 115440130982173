import React from "react";
import "./Testimonials.scss";
import { TestimonialsData } from "../../Assets/Data/constants";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RightBtn from "../../Assets/right-arrow.svg";

const CustomPrevArrow = ({ onClick }) => (
	<button className="prevArrow" onClick={onClick}>
		<img src={RightBtn} alt="" />
	</button>
);

const CustomNextArrow = ({ onClick }) => (
	<button className="nextArrow" onClick={onClick}>
		<img src={RightBtn} alt="" />
	</button>
);

const Testimonials = () => {
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 768,
				settings: {
					dots: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className="TestimonialsContainer">
			<h3 className="subtitle">
				{TestimonialsData && TestimonialsData.subtitle}
			</h3>
			<h2 className="titile">{TestimonialsData && TestimonialsData.title}</h2>
			<p className="Testimonialdesc">
				{TestimonialsData && TestimonialsData.desc}
			</p>
			<div className="slider-container">
				<Slider {...settings}>
					{TestimonialsData &&
						TestimonialsData.cardsData?.map((c, index) => (
							<div key={index} className="card">
								<div className="heading">
									<div className="companyLogoContainer">
										<img className="companyLogo" src={c.img} alt="" />
									</div>
									<div className="customerDetails">
										<div>
											<h4 className="name">{c.name}</h4>
											<h4 className="designation">{c.city}</h4>
										</div>
										<img className="rating" src={c.rating} alt="" />
									</div>
								</div>
								<div className="desc">{c.says}</div>
							</div>
						))}
				</Slider>
			</div>
		</div>
	);
};

export default Testimonials;
