import VAISHALI from "../VAISHALI LOGO.svg";
import Logo2 from "../logo2.svg";
import Swastik from "../Swastik.svg";
import VBD from "../VBD.svg";
import Image6 from "../image6.svg";
import Image5 from "../image5.svg";
import Image4 from "../image4.svg";
import Customer from "../customer.svg";
import Business from "../business.svg";
import Surendra from "../image4.svg";
import ValueChain from "../valueChain.svg";
import CIRating from "../22ciRating.svg";
import BaazRating from "../baazRating.svg";
import Insight1 from "../insight1.svg";
import ContactImg from "../contactImg.svg";
import Girja from "../image6.svg";
import Seghal from "../image5.svg";
import SeghalColored from "../SehgalColored.png";
import FullRating from "../fullRating.svg";

export const HeroSectionData = {
	title: "Transforming Real Estate Management",
	desc: "All-in-one platform for developers to optimize operations and showcase projects",
};

export const BannerData = {
	title: "Saved 30% for builders across India",
	logos: [
		{ img: VAISHALI },
		{ img: Logo2 },
		{ img: Swastik },
		{ img: VBD },
		{ img: Image6 },
		{ img: Image5 },
		{ img: Image4 },
	],
};

export const RealstateData = {
	title: "Comprehensive Real Estate Solutions for...",
	card: [
		{
			img: Customer,
			title: "Customer",
			desc: "HousinGram enhances customer experience by streamlining workflows and boosting communication between developers and buyers. It ensures transparency in project progress, giving customers clear visibility into their investments. With real-time updates and insights, customers can track developments, leading to quicker, more reliable project completions and increased satisfaction.",
		},
		{
			img: Business,
			title: "Business",
			desc: "Our real estate productivity app boosts business efficiency by optimizing project delivery and enhancing team collaboration. It provides tools for real-time tracking, resource allocation, and task management, helping developers complete projects on time and within budget.",
		},
		{
			img: ValueChain,
			title: "Value Chain",
			desc: "HousinGram connects architects, vendors, contractors, and service providers within the real estate value chain by offering performance insights, timely payments, and project visibility. It helps them build credibility, expand their network, and access better opportunities through streamlined workflows, while developers benefit from improved efficiency and high-quality services from trusted partners.",
		},
	],
};

export const InsightsPageData = [
	{
		title: "Empowering developers with actionable insights",
		desc: "HousinGram was founded by Karan Sehgal, leveraging over 15 years of experience in the real estate industry to create an all-in-one solution for developers and builders. Recognizing the complexities of real estate management, HousinGram aims to streamline operations, sales, and vendor relationships, enhancing efficiency across the board. Our vision is to empower real estate professionals to make informed decisions, optimize their projects, and build stronger communities, thus revolutionizing the real estate landscape through innovative, data-driven tools and insights.",
		rating: [
			{ img: CIRating },
			{
				img: BaazRating,
			},
		],
		img: Insight1,
	},
];

export const TestimonialsData = {
	subtitle: "Testimonials",
	title: "What clients says",
	desc: "Our goal is at the heart of all that we do. We make our clients happiness our sole priority.",
	cardsData: [
		{
			img: SeghalColored,
			name: "Sehgal Infra",
			city: "Jalandhar",
			rating: FullRating,
			says: "HousinGram transformed how we manage operations and sales. The app’s analytical tools helped us identify inefficiencies and improve performance across all business verticals. The team is responsive and supportive, ensuring seamless integration with our value chain. Our communication with vendors and customers has also improved, leading to faster decision-making and higher satisfaction.",
		},
		{
			img: VBD,
			name: "Ved Builders and Developers",
			city: "Bhopal",
			rating: FullRating,
			says: "Using HousinGram has been a game-changer for our business. Its comparative data analysis provided insights we never had before, boosting our sales and vendor management. The platform has simplified communication with our customers and team, making operations run smoother. We highly recommend it!",
		},
		{
			img: Girja,
			name: "Girja Colonisers",
			city: "Bhopal",
			rating: FullRating,
			says: "HousinGram’s all-in-one platform has streamlined our processes, from marketing to legal. The ease of coordinating with contractors and vendors has improved our project timelines. The support team is always available, and the app's user-friendly interface keeps us connected with our customers effortlessly.",
		},
		{
			img: Surendra,
			name: "Surendra Group",
			city: "Ahemdabad",
			rating: FullRating,
			says: "Managing multiple projects became much easier with HousinGram. Its powerful analytics gave us a clearer understanding of our business verticals, from HR to accounts. The app’s features strengthened our communication with suppliers and contractors, while the support team provided quick solutions whenever needed.",
		},
		{
			img: Swastik,
			name: "Swastik Developers",
			city: "Katni",
			rating: FullRating,
			says: "HousinGram helped us optimize our real estate operations. The app’s data-driven insights allowed us to make informed decisions, improving both sales and vendor relationships. Our interactions with clients and the team have never been smoother. The customer support from HousinGram is outstanding!",
		},
	],
};

export const ContactPageData = {
	title: "Get Started",
	img: ContactImg,
};
