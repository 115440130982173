import React from "react";
import Navbar from "../Navbar/Navbar";
import HeroSection from "../HeroSection/HeroSection";
import BannerPage from "../BannerPage/BannerPage";
import RealstatePage from "../RealstatePage/RealstatePage";
import InsightsPage from "../InsightsPage/InsightsPage";
import Footer from "../Footer/Footer";
import ContactPage from "../ContactPage/ContactPage";
import Testimonials from "../Testimonials/Testimonials";

const HomePage = () => {
	return (
		<div>
			<Navbar />
			<HeroSection />
			<BannerPage />
			<RealstatePage />
			<InsightsPage />
			<Testimonials />
			<ContactPage />
			<Footer />
		</div>
	);
};

export default HomePage;
