import React from "react";
import "./RealstatePage.scss";
import { RealstateData } from "../../Assets/Data/constants";

const RealstatePage = () => {
	return (
		<div className="RealstatePageContainer">
			<div className="realstate">
				<div className="titleConatiner">
					<h1 className="realstateTitle">
						{RealstateData && RealstateData.title}
					</h1>
					<button className="getStartedBtn"> Get Started</button>
				</div>
				<div className="gridConatiner">
					{RealstateData &&
						RealstateData.card?.map((c, index) => (
							<div key={index} className="gridItem">
								<div className="gridImg">
									<img src={c.img} alt="" />
								</div>
								<h2>{c.title}</h2>
								<p>{c.desc}</p>
							</div>
						))}
				</div>
			</div>
		</div>
	);
};

export default RealstatePage;
